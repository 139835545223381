<template>
  <article id="nav-article">
    <div id="navbar">
      <div class="nav-items">
        <div class="logo"></div>
        <div id="nav-link-div">
          <router-link :to="projectspath" v-show="isthispage('projects')">{{
            projects
          }}</router-link>
          <router-link :to="socialpath">{{ social }}</router-link>
          <router-link :to="contactpath">{{ contact }}</router-link>
          <router-link :to="aboutpath">{{ about }}</router-link>
        </div>
      </div>
      <div class="nav-bg"></div>
    </div>
  </article>
</template>
<script>
import App from "../App.vue";
export default {
  name: "Nav",
  data() {
    return {
      data: this.$route.fullPath,
      projects: "Projects",
      projectspath: "/projects",
      social: "Social",
      socialpath: "/social",
      contact: "Contact",
      contactpath: "/contact",
      about: "About",
      aboutpath: "/about",
      navlinkdiv: "",
    };
  },
  mounted() {
    this.bind();
    this.asd();
  },
  methods: {
    asd() {
      for (let index = 0; index < this.navlinkdiv.children.length; index++) {
        this.cursorOpacity(this.navlinkdiv.children[index]);
      }
    },
    bind() {
      this.navlinkdiv = document.getElementById("nav-link-div");
    },
    cursorOpacity(element) {
      element.addEventListener("mouseover", (e) => {
        App.data.call().cursorElement.children[0].style.opacity = "0%";
        element.setAttribute("style", "background-color: #27f579;");
      });
      element.addEventListener("mouseout", (e) => {
        App.data.call().cursorElement.children[0].style.opacity = "100%";
        element.setAttribute("style", "background-color: transparent;");
      });
    },

    isthispage: function (page) {
      if (this.data === "/") {
        return true;
      } else if (this.data === "/projects") {
        if (page === "projects") {
          this.projects = "Ana Sayfa";
          this.projectspath = "/";
          return true;
        }
      } else if (this.data === "/social") {
        this.social = "Ana Sayfa";
        this.socialpath = "/";
        return true;
      } else if (this.data === "/contact") {
        this.contact = "Ana Sayfa";
        this.contactpath = "/";
        return true;
      } else if (this.data === "/about") {
        this.about = "Ana Sayfa";
        this.aboutpath = "/";
        return true;
      }
    },
  },
};
</script>
<style scoped src='../styles/nav-style.css'>
</style>
